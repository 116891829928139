import { gql } from "@apollo/client";
import { MenuItem, AcfLink } from "graphql";
import Link from "next/link";
import { AnimatePresence, m, LazyMotion } from "framer-motion";
import { itemVariants, sideVariants } from "./Fragments/variants";
import { MenuButton } from "./Fragments/MenuButton";
const loadFeatures = () =>
  import("utilities/framerFeatures.js").then((res) => res.default);

interface NavigationMenuProps {
  menuItems: MenuItem[] | undefined;
  className?: string;
  type?: "primary" | "secondary";
  isOpen?: boolean;
  isHeaderPast?: boolean;
  toggleOpen?: () => void;
  formattedDateRange?: string;
  locationName?: string;
  location?: any;
  ctaLink?: AcfLink;
  ticketsOnSale: boolean;
  generalTickets: AcfLink;
}

const NavigationMenu = ({
  menuItems,
  className,
  type = "primary",
  isOpen,
  isHeaderPast,
  formattedDateRange,
  locationName,
  location,
  ctaLink,
  ticketsOnSale,
  generalTickets,
  toggleOpen,
}: NavigationMenuProps) => {
  if (!menuItems) {
    return null;
  }

  return (
    <>
      <LazyMotion features={loadFeatures}>
        <AnimatePresence>
          <MenuButton
            className={`absolute right-4 ${
              isOpen ? `-top-14` : `top-0`
            } z-60 md:hidden`}
            isOpen={isOpen}
            onClick={toggleOpen}
            lineProps={{ strokeLinecap: "round" }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            width="24"
            height="24"
            strokeWidth="6"
            color="#E5E8EC"
          />
          {isOpen && (
            <m.aside
              initial={{ height: 0 }}
              animate={{
                height: `100vh`,
              }}
              exit={{
                height: 0,
                transition: { delay: 0.7, duration: 0.3 },
              }}
              className={`relative z-50 grid mt-[-8rem] !h-[calc(100vh--8px)] w-[100vw] px-4 py-3 top-[160px] right-0 gap-12 auto-rows-max`}
            >
              <m.nav
                className={`h-fit font-heading right-0 z-40 flex flex-col items-center gap-4 text-base md:flex-row md:gap-0`}
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                {menuItems.map(({ path, label }) => (
                  <m.div
                    key={path}
                    whileHover={{ scale: 1.1 }}
                    variants={itemVariants}
                  >
                    <Link
                      href={path ?? "/"}
                      className={`mr-5 text-xl tracking-widest uppercase hover:text-primary-light ${
                        type === "primary" ? "text-primary" : "text-slate"
                      }`}
                    >
                      {label}
                    </Link>
                  </m.div>
                ))}
              </m.nav>
              <div
                className={`flex flex-col items-center justify-center text-center text-slate h-fit`}
              >
                {formattedDateRange && (
                  <p
                    className={`text-xl md:text-2xl lg:text-3xl font-heading tracking-widest mb-0`}
                  >
                    {formattedDateRange}
                  </p>
                )}
                {location && (
                  <Link
                    className={`flex flex-col items-center justify-center text-slate hover:text-secondary`}
                    // href={`https://www.google.com/maps/search/?api=1&query=${location?.streetAddress}&query_place_id=${location?.placeId}`}
                    href={location.url ?? ``}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p
                      className={`text-sm md:text-md font-body uppercase mb-0`}
                    >
                      {locationName}
                    </p>
                    <p
                      className={`text-md md:text-lg font-body uppercase mb-0`}
                    >
                      San Antonio, TX
                    </p>
                  </Link>
                )}
              </div>
              {ticketsOnSale && generalTickets ? (
                <div className={`mx-auto h-fit`}>
                  <Link
                    className={`flex text-center rounded-md bg-secondary border-2 border-secondary py-2 px-4 text-md font-body font-bold text-black uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out`}
                    href={generalTickets.url ?? ``}
                    target="_blank"
                  >
                    {generalTickets.title}
                  </Link>
                </div>
              ) : (
                <>
                  {ctaLink && (
                    <div className={`mx-auto h-fit`}>
                      <Link
                        className={`flex text-center rounded-md bg-secondary border-2 border-secondary py-2 px-4 text-md font-body font-bold text-black uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out`}
                        href={ctaLink.url ?? ``}
                      >
                        {ctaLink.title}
                      </Link>
                    </div>
                  )}
                </>
              )}
            </m.aside>
          )}
        </AnimatePresence>
      </LazyMotion>
      <nav className="font-heading hidden flex-col items-center text-base md:relative md:flex md:flex-row">
        {menuItems.map(({ path, label }) => (
          <Link
            key={path}
            href={path ?? "/"}
            className={`mr-5 text-2xl tracking-widest uppercase hover:text-primary-light ${
              type === "primary" ? "text-slate" : "text-primary"
            }`}
          >
            {label}
          </Link>
        ))}
      </nav>
    </>
  );
};

export default NavigationMenu;

NavigationMenu.fragments = {
  entry: gql`
    fragment NavigationMenuItemFragment on MenuItem {
      id
      path
      label
      parentId
      cssClasses
      menu {
        node {
          name
        }
      }
    }
  `,
};
