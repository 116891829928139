import { FeaturedImage } from "components/FeaturedImage";

const planetStar = {
  sourceUrl: "/svg-bgs/planet-star.svg",
  mediaDetails: { width: 406.24, height: 462.73 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const rocketShip = {
  sourceUrl: "/svg-bgs/rocket-ship.svg",
  mediaDetails: { width: 553.5, height: 597.44 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const cometStar = {
  sourceUrl: "/svg-bgs/comet-star.svg",
  mediaDetails: { width: 414.03, height: 756.74 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const astronaut = {
  sourceUrl: "/svg-bgs/astronaut.svg",
  mediaDetails: { width: 515.54, height: 599.68 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const radar = {
  sourceUrl: "/svg-bgs/radar.svg",
  mediaDetails: { width: 457.52, height: 606.38 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};
const spaceShuttle = {
  sourceUrl: "/svg-bgs/space-shuttle.svg",
  mediaDetails: { width: 485.58, height: 464.46 },
  altText: "Starburst graphic",
  sizes: `(max-width: 800px) 100vw, 800px`,
};

// const planetRocketship =
//   backgroundImage && backgroundImage[0] === "planet-rocketship";
// const rocketshipPlanet =
//   backgroundImage && backgroundImage[0] === "rocketship-planet";
// const planetSpaceshuttle =
//   backgroundImage && backgroundImage[0] === "planet-spaceshuttle";
// const spaceshuttlePlanet =
//   backgroundImage && backgroundImage[0] === "spaceshuttle-planet";
// const astronautComet =
//   backgroundImage && backgroundImage[0] === "astronaut-comet";
// const cometAstronaut =
//   backgroundImage && backgroundImage[0] === "comet-astronaut";
// const radarSpaceshuttle =
//   backgroundImage && backgroundImage[0] === "radar-spaceshuttle";
// const spaceshuttleRadar =
//   backgroundImage && backgroundImage[0] === "spaceshuttle-radar";
// const cometRight =
//   backgroundImage && backgroundImage[0] === "comet-right";
// const cometLeft =
//   backgroundImage && backgroundImage[0] === "comet-left";

interface BgGraphicProps {
  bgType: any;
}

const BgGraphic = ({ bgType }: BgGraphicProps) => {
  let name = bgType;

  switch (name) {
    case "planet-rocketship": {
      return (
        <>
          <FeaturedImage
            className={`absolute top-8 left-8 w-[24rem]`}
            image={planetStar}
          />
          <FeaturedImage
            className={`absolute bottom-12 right-6 w-[24rem]`}
            image={rocketShip}
          />
        </>
      );
    }
    case "rocketship-planet": {
      return (
        <>
          <FeaturedImage
            className={`absolute top-8 right-8 w-[24rem]`}
            image={planetStar}
          />
          <FeaturedImage
            className={`absolute bottom-12 left-6 w-[24rem]`}
            image={rocketShip}
          />
        </>
      );
    }
    case "astronaut-comet": {
      return (
        <>
          <FeaturedImage
            className={`absolute bottom-12 left-4 w-[17rem]`}
            image={astronaut}
          />
          <FeaturedImage
            className={`absolute top-12 right-4 w-[15rem]`}
            image={cometStar}
          />
        </>
      );
    }
    case "comet-astronaut": {
      return (
        <>
          <FeaturedImage
            className={`absolute bottom-12 right-4 w-[15rem]`}
            image={astronaut}
          />
          <FeaturedImage
            className={`absolute top-12 left-4 w-[20rem]`}
            image={cometStar}
          />
        </>
      );
    }
    case "radar-spaceshuttle": {
      return (
        <>
          <FeaturedImage
            className={`absolute bottom-8 left-6 w-[15rem]`}
            image={radar}
          />
          <FeaturedImage
            className={`absolute top-8 right-6 w-[20rem]`}
            image={spaceShuttle}
          />
        </>
      );
    }
    case "spaceshuttle-radar": {
      return (
        <>
          <FeaturedImage
            className={`absolute bottom-8 right-6 w-[15rem]`}
            image={radar}
          />
          <FeaturedImage
            className={`absolute top-8 left-6 w-[20rem]`}
            image={spaceShuttle}
          />
        </>
      );
    }
    case "planet-spaceshuttle": {
      return (
        <>
          <FeaturedImage
            className={`absolute top-8 left-8 w-[24rem]`}
            image={planetStar}
          />
          <FeaturedImage
            className={`absolute bottom-12 right-6 w-[20rem]`}
            image={spaceShuttle}
          />
        </>
      );
    }
    case "spaceshuttle-planet": {
      return (
        <>
          <FeaturedImage
            className={`absolute top-8 right-8 w-[24rem]`}
            image={planetStar}
          />
          <FeaturedImage
            className={`absolute bottom-12 left-6 w-[20rem]`}
            image={spaceShuttle}
          />
        </>
      );
    }
    case "comet-right": {
      return (
        <FeaturedImage
          className={`absolute top-12 right-6 w-[20rem]`}
          image={cometStar}
        />
      );
    }
    case "comet-left": {
      return (
        <FeaturedImage
          className={`absolute top-12 -left-36 w-[20rem]`}
          image={cometStar}
        />
      );
    }
    case "radar-right": {
      return (
        <FeaturedImage
          className={`absolute bottom-8 right-6 w-[15rem]`}
          image={radar}
        />
      );
    }
    case "radar-left": {
      return (
        <FeaturedImage
          className={`absolute bottom-8 left-6 w-[15rem]`}
          image={radar}
        />
      );
    }

    default: {
      return null;
    }
  }
};

export default BgGraphic;
