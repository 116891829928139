import clsx from "clsx";
import { Content } from "components/Content";
import { FlexibleContentBlocksTextImage } from "graphql";
import useMediaQuery from "utilities/useMediaQuery";
import { FeaturedImage } from "components/FeaturedImage";
import Link from "next/link";
import Image from "next/image";
// import { Gallery } from "components/Carousel";
import { Carousel } from "components/Carousel";

const googleBadge = {
  sourceUrl: "/google-play-badge.png",
  mediaDetails: { width: 336, height: 100 },
  altText: "Google Play Store Badge",
  sizes: `(max-width: 336px) 100vw, 336px`,
};
const appleBadge = {
  sourceUrl: "/apple-store-badge.png",
  mediaDetails: { width: 336, height: 100 },
  altText: "Apple Store Badge",
  sizes: `(max-width: 336px) 100vw, 336px`,
};

interface GalleryBlockProps extends FlexibleContentBlocksTextImage {
  className?: string;
}

const GalleryBlock = ({
  className,
  sectionTitle,
  sectionContent,
  hasAppLinks,
  ctas,
  appleAppLink,
  googleAppLink,
  fontColor,
  mediaRight,
  photoGallery,
}: GalleryBlockProps) => {
  const hasGallery = photoGallery && photoGallery?.nodes?.length > 0;

  const isMobile = useMediaQuery("(max-width: 768px)");

  let innerSectionClass = clsx(
    `flex flex-col items-center justify-between mx-auto gap-8 relative h-full max-w-screen-2xl px-4 md:px-8 py-7 relative z-10`,
    mediaRight ? `mdl:flex-row` : `mdl:flex-row-reverse`,
  );

  let innerContentClass = clsx(`w-full mdl:w-[60%]`);

  let innerContentTitleClass = clsx(
    `font-heading text-4xl md:text-[3.5rem] leading-none text-center font-bold tracking-widest text-${fontColor}`,
  );

  let secContentClass = clsx(
    `font-body text-md tracking-wider mt-4 mb-6 text-${fontColor}`,
  );

  let appLinksClass = clsx(
    `flex flex-col md:flex-row gap-4 max-w-[50%] mt-4 mb-8`,
  );

  let sideMediaClass = clsx(`grid w-full mdl:w-[40%]`);

  const renderImageSlide = (photoGallery: any, index: number) => {
    const { altText, sourceUrl } = photoGallery || {};
    const imageProps = {
      src: sourceUrl || "",
      alt: altText || "",
    };
    const imageClass = clsx("w-full h-full");
    return (
      <div
        key={index}
        className={`relative overflow-hidden w-full h-[275px] mdl:h-[22vw]`}
      >
        <Image
          className={imageClass}
          {...imageProps}
          fill={true}
          loading="eager"
          style={{ objectFit: "cover" }}
        />
      </div>
    );
  };

  return (
    <div className={`${className} ${innerSectionClass}`}>
      <div className={innerContentClass}>
        {sectionTitle && (
          // <h3 className={innerContentTitleClass}>{sectionTitle}</h3>
          <Content className={innerContentTitleClass} content={sectionTitle} />
        )}
        {sectionContent && (
          <Content className={secContentClass} content={sectionContent} />
        )}

        {hasAppLinks && (
          <div className={appLinksClass}>
            {googleAppLink && (
              <Link
                href={googleAppLink?.url ?? ""}
                target={googleAppLink?.target ?? "_self"}
              >
                <FeaturedImage className={``} image={googleBadge} />
              </Link>
            )}
            {appleAppLink && (
              <Link
                href={appleAppLink?.url ?? ""}
                target={appleAppLink?.target ?? "_self"}
              >
                <FeaturedImage className={``} image={appleBadge} />
              </Link>
            )}
          </div>
        )}

        {ctas &&
          ctas.map((cta, index) => {
            const link = cta?.link;
            const isPrimary = cta?.type && cta?.type[0] === "primary";
            const isSecondary = cta?.type && cta?.type[0] === "secondary";

            let btnType = clsx(
              isPrimary &&
                `rounded-md bg-secondary border-2 border-secondary py-2 px-4 font-bold text-black font-sans uppercase hover:bg-white hover:text-secondary transition duration-300 ease-in-out tracking-wider`,
              isSecondary &&
                `bg-white rounded-md border-2 border-secondary py-2 px-4 font-bold text-secondary font-sans uppercase hover:bg-secondary hover:text-white hover:border-secondary transition duration-300 ease-in-out tracking-wider`,
            );

            return (
              <Link
                key={`${index}-${cta}`}
                href={link?.url ?? ""}
                target={link?.target ?? "_self"}
                className={btnType}
              >
                {link?.title}
              </Link>
            );
          })}
      </div>
      {hasGallery && (
        <div className={sideMediaClass}>
          {/* <Gallery images={photoGallery} numNgroups={"1"} /> */}
          <Carousel
            items={photoGallery?.nodes ?? []}
            renderSlide={renderImageSlide}
            numNgroups={1}
            arrows
          />
        </div>
      )}
    </div>
  );
};

export default GalleryBlock;
