import { gql } from "@apollo/client";
import { FlexibleContentBlocksTextImage } from "graphql";
import { MEDIA_ITEM_FRAGMENT } from "fragments";
import clsx from "clsx";
import { Content } from "components/Content";
import useMediaQuery from "utilities/useMediaQuery";
import { FeaturedImage } from "components/FeaturedImage";

import BasicBlock from "./Fragments/BasicBlock";
import GalleryBlock from "./Fragments/GalleryBlock";
import LetterBlock from "./Fragments/LetterBlock";
import { BgGraphic } from "components/BgGraphic";

interface TextImageProps extends FlexibleContentBlocksTextImage {
  className?: string;
}

const TextImage = ({
  className,
  variant,
  sectionTitle,
  sectionContent,
  hasAppLinks,
  ctas,
  appleAppLink,
  googleAppLink,
  backgroundColor,
  backgroundImage,
  fontColor,
  mediaRight,
  sectionImage,
  sectionMap,
  photoGallery,
  afterImageContent,
  signatureName,
  writersPosition,
}: TextImageProps) => {
  const isBasic = variant && variant[0] === "basic";
  const isLetter = variant && variant[0] === "letter";
  const isGallery = variant && variant[0] === "gallery";
  const isMap = variant && variant[0] === "map";

  const isMobile = useMediaQuery("(max-width: 768px)");

  let wrapperClass = clsx(
    `grid bg-${backgroundColor} items-center justify-center overflow-hidden relative`,
  );

  let bgItemClass = clsx(`absolute w-full h-full z-0`);

  const noBgImage = backgroundImage && backgroundImage[0] === "none";
  let bgGraphicType = "";
  if (backgroundImage && backgroundImage[0] === "planet-rocketship") {
    bgGraphicType = "planet-rocketship";
  } else if (backgroundImage && backgroundImage[0] === "rocketship-planet") {
    bgGraphicType = "rocketship-planet";
  } else if (backgroundImage && backgroundImage[0] === "astronaut-comet") {
    bgGraphicType = "astronaut-comet";
  } else if (backgroundImage && backgroundImage[0] === "comet-astronaut") {
    bgGraphicType = "comet-astronaut";
  } else if (backgroundImage && backgroundImage[0] === "radar-spaceshuttle") {
    bgGraphicType = "radar-spaceshuttle";
  } else if (backgroundImage && backgroundImage[0] === "spaceshuttle-radar") {
    bgGraphicType = "spaceshuttle-radar";
  } else if (backgroundImage && backgroundImage[0] === "comet-right") {
    bgGraphicType = "comet-right";
  } else if (backgroundImage && backgroundImage[0] === "comet-left") {
    bgGraphicType = "comet-left";
  } else if (backgroundImage && backgroundImage[0] === "radar-right") {
    bgGraphicType = "radar-right";
  } else if (backgroundImage && backgroundImage[0] === "radar-left") {
    bgGraphicType = "radar-left";
  }

  return (
    <section className={`${className ? className : ``} ${wrapperClass}`}>
      {(isBasic || isMap) && (
        <BasicBlock
          variant={variant}
          sectionTitle={sectionTitle}
          sectionContent={sectionContent}
          hasAppLinks={hasAppLinks}
          ctas={ctas}
          appleAppLink={appleAppLink}
          googleAppLink={googleAppLink}
          fontColor={fontColor}
          mediaRight={mediaRight}
          sectionImage={sectionImage}
          sectionMap={sectionMap}
        />
      )}
      {isGallery && (
        <GalleryBlock
          sectionTitle={sectionTitle}
          sectionContent={sectionContent}
          hasAppLinks={hasAppLinks}
          ctas={ctas}
          appleAppLink={appleAppLink}
          googleAppLink={googleAppLink}
          fontColor={fontColor}
          mediaRight={mediaRight}
          photoGallery={photoGallery}
        />
      )}
      {isLetter && (
        <LetterBlock
          sectionTitle={sectionTitle}
          sectionContent={sectionContent}
          afterImageContent={afterImageContent}
          signatureName={signatureName}
          writersPosition={writersPosition}
          fontColor={fontColor}
          mediaRight={mediaRight}
          sectionImage={sectionImage}
        />
      )}
      {!noBgImage && (
        <div className={bgItemClass} style={{ gridArea: "1/1" }}>
          <BgGraphic bgType={bgGraphicType} />
        </div>
      )}
    </section>
  );
};

export default TextImage;

TextImage.fragments = {
  entry: gql`
    fragment TextImageFragment on FlexibleContentBlocksTextImage {
      __typename
      variant
      sectionTitle
      sectionContent
      afterImageContent
      signatureName
      writersPosition
      hasAppLinks
      ctas {
        type
        link {
          title
          url
          target
        }
      }
      appleAppLink {
        title
        url
        target
      }
      googleAppLink {
        title
        url
        target
      }
      backgroundColor
      backgroundImage
      fontColor
      mediaRight
      sectionImage {
        node {
          ...MediaItemFragment
        }
      }
      sectionMap {
        title
        url
        target
      }
      photoGallery(first: 15) {
        nodes {
          ...MediaItemFragment
        }
      }
    }
    ${MEDIA_ITEM_FRAGMENT}
  `,
};
